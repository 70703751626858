import { API_URL } from '@/npc-api/config'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { GATSBY_SIGNATURE } from '@/npc-api/config'
import { useTranslation } from 'react-i18next'
const Header = () => {
  const [jobsCount, setJobsCount] = useState<number>(0)
  const { t } = useTranslation()
  const fetchJobs = async () => {
    const res = await axios.post(`${API_URL}/jobs/allJobs`, {
      signature: GATSBY_SIGNATURE,
    })
    setJobsCount(
      res.data.slice(0, res.data.length - 1).filter((job: any) => job.jobs.length > 0).length,
    )
  }
  useEffect(() => {
    fetchJobs()
  }, [])

  const goJobOpening = () => {
    if (typeof window !== 'undefined') {
      const element = window.document.getElementById('job-opening-section')
      element?.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }
  const viewOpeningsBtnViewPart = t('headerSection.viewOpeningsBtnViewPart')
  const viewOpeningsBtnOpeningsPart = t('headerSection.viewOpeningsBtnOpeningsPart')
  const viewOpeningsText = `${viewOpeningsBtnViewPart} ${jobsCount} ${viewOpeningsBtnOpeningsPart}`
  return (
    <section className="section career-header bg-image">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col p-5">
            <div className="content-wrapper position-relative">
              <h1 className="wow-outer text-white text-center">
                {t('headerSection.comeWork')} <br />
                {t('headerSection.whithUs')}
              </h1>
              <p className="text-white text-center">{t('headerSection.subtitle')}</p>
              <div className="wow-outer button-outer">
                <a
                  className="button light-btn button-winona wow slideInLeft"
                  onClick={() => goJobOpening()}
                >
                  {viewOpeningsText}

                  <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
