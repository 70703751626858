import React from 'react'
import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'

const Subscribe = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const handleClick = () => {
    const subscribeButton = document.getElementById('subscribe-button')
    subscribeButton?.click()
  }

  return (
    <section
      className={`section section-xs text-center p-24 ${
        pathname === '/careers' ? 'subscribe-career-section' : 'subscribe-details-section'
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h3 className="wow-outer text-white">
              <span className="wow slideInDown">
                {t('subscribeSection.title')}
                <br />
                <span className="fw-bold">{t('subscribeSection.subtitle')}</span>
              </span>
            </h3>

            <button
              className="button light-btn button-winona wow slideInDown"
              data-wow-delay=".1s"
              style={{ margin: 0 }}
              onClick={handleClick}
            >
              {t('subscribeSection.subscribeButton')}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscribe
