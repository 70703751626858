import React from 'react'
import { JobListType } from './JobOpening'
import { Link } from 'gatsby-plugin-react-i18next'

export type jobsProp = {
  name: string

  description?: string

  location?: string

  jobType?: string

  division?: string

  jobGrade?: string

  reportsTo?: string

  flsa?: string

  summary?: string

  keyDuties?: {
    keyDuties: string[]
  }

  duties?: {
    duties: string[]
  }

  minQualifications?: {
    minQualifications: string[]
  }

  preferedQualifications?: {
    preferedQualifications: string[]
  }

  enviroment?: {
    enviroment: string[]
  }

  physicalDemands?: {
    physicalDemands: string[]
  }

  departmentId: string

  createdAt: string

  id: string
}

const JobList = (listJobs: JobListType) => {
  return (
    <div className="row">
      <div className="col">
        <>
          {listJobs.jobs.map(
            (job, indx) =>
              job.jobs.length > 0 && (
                <div key={indx} className="job-list">
                  <div>
                    <div className="row job-wrapper">
                      <div className="col">
                        <h5>{job.name}</h5>
                      </div>
                    </div>
                    {job.jobs.map((role, indx) => (
                      <div className="row job-role-wrapper" key={indx}>
                        <div className="col-md-9 job-list-wrapper">
                          <h6>{role.name}</h6>
                          <p>{role.description}</p>
                          <div>
                            <span>{role?.jobType === 'fulltime' ? 'Full time' : 'Part time'}</span>
                            <span>{role.location}</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <Link
                            className="button light-btn button-winona wow slideInDown"
                            to={`/job-details/${role.id}`}
                            placeholder=""
                          >
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ),
            51,
          )}
        </>
      </div>
    </div>
  )
}

export default JobList
