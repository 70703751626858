import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL, GATSBY_SIGNATURE } from '@/npc-api/config'
import JobList from './JobList'
import { useTranslation } from 'react-i18next'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'
export type jobProps = {
  name: string
  jobs: any[]
}
export type JobListType = {
  jobs: jobProps[]
}

const JobOpening = () => {
  const [jobType, setJobMode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [jobs, setJobs] = useState<any[]>([])
  const [name, setName] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [locations, setLocations] = useState<string[]>([])
  const [showLocation, setShowLocation] = useState<boolean>(true)
  const [showJobType, setShowJobType] = useState<boolean>(true)
  const jobTypeSelectBox =
    typeof window !== 'undefined' && window.document.querySelector<HTMLElement>('.job-select-box')
  const jobTypeSelect =
    typeof window !== 'undefined' && window.document.querySelector<HTMLElement>('.job-type-select')
  const locationSelectBox =
    typeof window !== 'undefined' &&
    window.document.querySelector<HTMLElement>('.location-select-box')
  const locationSelect =
    typeof window !== 'undefined' && window.document.querySelector<HTMLElement>('.location-select')

  const jobTypeOptions = [
    { title: 'All', value: '' },
    { title: 'Full Time', value: 'fulltime' },
    { title: 'Part Time', value: 'part-time' },
  ]
  const { t } = useTranslation()
  const fetchJobs = async () => {
    try {
      setIsLoading(true)
      setError(false)

      GTM.sendEvent({
        event: GTMEventType.QueryJobs,
        job_name: name,
        job_location: location,
        job_type: jobType,
      })

      const res = await axios.post(`${API_URL}/jobs/allJobs`, {
        name,
        location,
        jobType,
        signature: GATSBY_SIGNATURE,
      })
      setIsLoading(false)
      setJobs(res.data.slice(0, res.data.length - 1).filter((job: any) => job.jobs.length > 0))
      setLocations(res.data[res.data.length - 1].locations)
    } catch (error) {
      setIsLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const showJobTypeCheckboxes = () => {
    if (showJobType) {
      if (jobTypeSelectBox) {
        jobTypeSelectBox.classList.add('show')
        setShowJobType(false)
      }
    } else {
      if (jobTypeSelectBox) {
        jobTypeSelectBox.classList.remove('show')
        setShowJobType(true)
      }
    }
  }

  const showLocationCheckboxes = () => {
    if (showLocation) {
      if (locationSelectBox) {
        locationSelectBox.classList.add('show')
        setShowLocation(false)
      }
    } else {
      if (locationSelectBox) {
        locationSelectBox.classList.remove('show')
        setShowLocation(true)
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && jobTypeSelectBox && jobTypeSelect) {
      if (jobTypeSelectBox?.classList.contains('show')) {
        window.document.addEventListener('click', (e: any) => {
          if (!jobTypeSelect?.contains(e.target)) {
            jobTypeSelectBox?.classList.remove('show')
            setShowJobType(true)
          }
        })
      }
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.document.removeEventListener('click', () => {})
      }
    }
  }, [showJobType])

  useEffect(() => {
    if (typeof window !== 'undefined' && locationSelectBox && locationSelect) {
      if (locationSelectBox?.classList.contains('show')) {
        window.document.addEventListener('click', (e: any) => {
          if (!locationSelect?.contains(e.target)) {
            locationSelectBox?.classList.remove('show')
            setShowLocation(true)
          }
        })
      }
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.document.removeEventListener('click', () => {})
      }
    }
  }, [showLocation])

  return (
    <section className="section section-lg job-opening-section p-24" id="job-opening-section">
      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <h3 className="av-title text-center">{t('jobOpeningSection.title1')}</h3>
            <h4 className="text-center mt-2">{t('jobOpeningSection.title2')}</h4>
          </div>
        </div>
        <div className="row filter-wrapper">
          <div className="col-md-3 wow-outer">
            <div className="form-wrap wow fadeSlideInUp">
              <label className="form-label-outside" htmlFor="job-title">
                {t('jobOpeningSection.searchLabel')}
              </label>
              <input
                name="title"
                className="filter-item"
                onChange={(e) => setName(e.target.value)}
                autoFocus={false}
                placeholder={t('jobOpeningSection.searchPlaceholder')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-wrap wow fadeSlideInUp">
              <label className="form-label-outside" htmlFor="job-location">
                {t('jobOpeningSection.locationLabel')}
              </label>
              <div className="location-select">
                <div className="location-select-box" onClick={showLocationCheckboxes}>
                  <select>
                    {location ? (
                      <option>{location}</option>
                    ) : (
                      <option onClick={() => setLocation('')}>
                        {t('jobOpeningSection.allOption')}
                      </option>
                    )}
                  </select>
                  <div className="overSelect"></div>
                </div>

                <div className="checkboxes">
                  <label htmlFor="allLocations" className="item" onClick={() => setLocation('')}>
                    <input type="radio" id="allLocations" name="location" />
                    {t('jobOpeningSection.allOption')}
                  </label>
                  {locations &&
                    locations.map((item, i) => (
                      <label
                        key={i}
                        htmlFor={item}
                        className="item"
                        onClick={() => setLocation(item)}
                      >
                        <input type="radio" id={item} name="location" />
                        {item}
                      </label>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-wrap wow fadeSlideInUp">
              <label className="form-label-outside" htmlFor="job-type">
                {t('jobOpeningSection.jobTypeLabel')}
              </label>
              <div className="job-type-select">
                <div className="job-select-box" onClick={showJobTypeCheckboxes}>
                  <select>
                    {jobType ? (
                      <option>{jobType === 'fulltime' ? 'Full Time' : 'Part Time'}</option>
                    ) : (
                      <option onClick={() => setJobMode('')}>
                        {t('jobOpeningSection.allOption')}
                      </option>
                    )}
                  </select>
                  <div className="overSelect"></div>
                </div>
                <div className="checkboxes">
                  {jobTypeOptions.map((item, i) => (
                    <label
                      key={i}
                      htmlFor={jobType ? item.value : 'allJobTypes'}
                      className="item"
                      onClick={() => setJobMode(item.value)}
                    >
                      <input
                        type="radio"
                        id={jobType ? item.value : 'allJobTypes'}
                        name="jobType"
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 button-outer">
            <button
              disabled={isLoading}
              className="button button-winona wow slideInDown filter-button mb-5"
              onClick={() => {
                fetchJobs()
              }}
            >
              {isLoading ? t('jobOpeningSection.loadingText') : t('jobOpeningSection.applyFilters')}
              {!isLoading && (
                <img src="/images/w-arrow-right.svg" className="ms-2" alt="arrow-icon" />
              )}
            </button>
          </div>
        </div>
        {jobs.length === 0 && !isLoading && (
          <div className="mb-5">{t('jobOpeningSection.notAvailableText')}</div>
        )}
        {isLoading && <div>{t('jobOpeningSection.loadingText')}</div>}
        {!isLoading && jobs && <JobList jobs={jobs} />}
        <h3 className="av-title text-center">{t('jobOpeningSection.cantFindRoleTitle')}</h3>
        <p className="text-center">{t('jobOpeningSection.cantFindRoleSubtitle')}</p>
        <p className="text-center mt-0">
          {t('jobOpeningSection.sendEmailText')}{' '}
          <span>
            <a href="mailto:info@avilasaccounting.com">info@avilasaccounting.com</a>{' '}
          </span>
          {t('jobOpeningSection.sendEmailInfo')}
        </p>
      </div>
    </section>
  )
}

export default JobOpening
