import React from 'react'
import { useTranslation } from 'react-i18next';

const WhyJoinUs = () => {
  const { t } = useTranslation();
  return (
    <section className="section section-lg careers-section p-24">
      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <h3 className="av-title text-center">{t('careersSection.title')}</h3>
            <h4 className="text-center mt-2">{t('careersSection.subtitle')}</h4>
            <ul className="d-flex">
              <div className="row mt-5">
                <div className="col-md-6">
                  {/* Primer grupo de beneficios */}
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.0.title')}</h5>
                        <p>{t('careersSection.benefitsList.0.description')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.1.title')}</h5>
                        <p>{t('careersSection.benefitsList.1.description')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.2.title')}</h5>
                        <p>{t('careersSection.benefitsList.2.description')}</p>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-md-6">
                  {/* Segundo grupo de beneficios */}
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.3.title')}</h5>
                        <p>{t('careersSection.benefitsList.3.description')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.4.title')}</h5>
                        <p>{t('careersSection.benefitsList.4.description')}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row content-wrapper">
                      <div className="col-1 mb-4">
                        <img src="/images/check-circle.svg" alt={t('careersSection.checkIconAltText')} />
                      </div>
                      <div className="col">
                        <h5>{t('careersSection.benefitsList.3.title')}</h5>
                        <p>{t('careersSection.benefitsList.3.description')}</p>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyJoinUs
