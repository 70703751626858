import React from 'react'
import Header from './Header'
import JobOpening from './JobOpening'
import Subscribe from './Subscribe'
import WhyJoinUs from './WhyJoinUs'

const Career = () => {
  return (
    <>
      <Header />
      <WhyJoinUs />
      <JobOpening />
      <Subscribe />
    </>
  )
}

export default Career
