import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import Header from '@/components/Header'
import Career from '@/components/Career'
import { graphql } from 'gatsby'

const Services = () => {
  return (
    <Layout>
      <SEO title="Careers" canonical={`${useLocation().host}`} />
      <Header />
      <Career />
      <Footer />
    </Layout>
  )
}

export default Services
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
